<template lang="pug">
  v-container
    v-row
      v-col
        v-card(
          outlined
        )
          v-card-title
            v-layout(
              flex
            )
              v-text-field.flex-shrink-1.flex-grow-0(
                v-model.trim="search"
                label="Search Shops"
                single-line
                prepend-inner-icon="mdi-magnify"
                hide-details
                filled
                :loading="(search !== null || search !== '') && areShopsPending"
                rounded
                dense
              )
          v-data-table.clickable(
            :headers="headers"
            :loading="areShopsPending"
            no-data-text="No Shops Found"
            :options.sync="options.page"
            :items="shops"
            @click:row="(item) => $router.push({name: 'shop', params: { shopId: item.id }})"
            :footer-props="{ itemsPerPageOptions: [5,10,15] }"
          )
            template(v-slot:item.usesOrdering="{ item: shop }")
              FeathersVuexCount(
                v-slot="{ total }"
                service="orders"
                :params="{ query: {shop_id: shop.id, createdAt: {$gt: $day().subtract(2, 'months').toISOString()}} }"
              )
                v-icon(
                  v-if="shop.usesOrdering && total"
                  left
                ) mdi-check
            template(v-slot:item.usesInvoicing="{ item: shop }")
              FeathersVuexCount(
                v-slot="{ total }"
                service="invoices"
                :params="{ query: {shop_id: shop.id, createdAt: {$gt: $day().subtract(2, 'months').toISOString()}} }"
              )
                v-icon(
                  v-if="shop.usesInvoicing && total"
                  left
                ) mdi-check

</template>

<script>
import { useFind } from 'feathers-vuex'
import { computed, ref } from '@vue/composition-api'

export default {
  name: 'MultiShopOrganizations',
  setup (props, context) {
    const { Shop } = context.root.$FeathersVuex.api
    const currentUser = context.root.$store.getters['auth/user']

    // Setups up a reference that the search can v-model
    const search = ref(null)

    // Basic starter datatable attributes
    const options = ref({
      page: 1,
      itemsPerPage: 10,
      sortBy: ['createdAt'],
      sortDesc: [true]
    })

    // Compute the sort based on various datatable properties
    const sortBy = computed(() => {
      var obj = {}
      if (options.value.sortBy && options.value.sortBy.length) {
        obj[options.value.sortBy[0]] = options.value.sortDesc[0] ? -1 : 1
      }
      return obj
    })

    const limit = computed(() => {
      if (options.value.itemsPerPage !== -1) {
        return options.value.itemsPerPage
      } else {
        return 999
      }
    })

    const query = computed(() => {
      // Construct the base query
      const query = {
        $skip: options.value.itemsPerPage * (options.value.page - 1),
        $sort: sortBy.value
      }

      return query
    })

    const params = computed(() => {
      const params = { ...query.value }

      if (search.value !== null && search.value !== '') {
        params.name = {
          $regex: search.value,
          $options: 'gi'
        }
      }

      return { query: params }
    })

    const fetchParams = computed(() => {
      const fetchParams = { ...query.value }

      if (search.value !== null && search.value !== '') {
        fetchParams.name = {
          $iRegexp: search.value
        }
      }

      return { query: { ...fetchParams, $eager: 'region', mso_id: currentUser.mso_id } }
    })

    // Do the actual querying, be sure to remember to paginate
    const { items: shops, isPending: areShopsPending, paginationData, haveLoaded: haveShopsLoaded, find } = useFind({
      model: Shop,
      params,
      fetchParams,
      paginate: true
    })

    return {
      find,
      search,
      options,
      sortBy,
      limit,
      shops,
      query,
      params,
      fetchParams,
      areShopsPending,
      haveShopsLoaded,
      paginationData
    }
  },
  computed: {
    user () {
      return this.$store.getters['auth/user']
    },
    headers () {
      const headers = []

      headers.push({
        text: 'Name',
        value: 'name'
      })

      if (this.user.isAdmin) {
        headers.push({
          text: 'Region',
          value: 'region_id'
        })
      }

      headers.push({
        text: 'State',
        value: 'state'
      })

      if (this.user.isAdmin || this.user.isRegionAdmin || this.user.isMso) {
        headers.push({
          text: 'Active Ordering',
          value: 'usesOrdering'
        })
      }
      if (this.user.isAdmin || this.user.isRegionAdmin || this.user.isMso) {
        headers.push({
          text: 'Active Cost Recovery',
          value: 'usesInvoicing'
        })
      }

      return headers
    }
  },
  data: () => ({

  })
}
</script>
