var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_c('v-layout',{attrs:{"flex":""}},[_c('v-text-field',{staticClass:"flex-shrink-1 flex-grow-0",attrs:{"label":"Search Shops","single-line":"","prepend-inner-icon":"mdi-magnify","hide-details":"","filled":"","loading":(_vm.search !== null || _vm.search !== '') && _vm.areShopsPending,"rounded":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"search"}})],1)],1),_c('v-data-table',{staticClass:"clickable",attrs:{"headers":_vm.headers,"loading":_vm.areShopsPending,"no-data-text":"No Shops Found","options":_vm.options.page,"items":_vm.shops,"footer-props":{ itemsPerPageOptions: [5,10,15] }},on:{"update:options":function($event){return _vm.$set(_vm.options, "page", $event)},"click:row":function (item) { return _vm.$router.push({name: 'shop', params: { shopId: item.id }}); }},scopedSlots:_vm._u([{key:"item.usesOrdering",fn:function(ref){
var shop = ref.item;
return [_c('FeathersVuexCount',{attrs:{"service":"orders","params":{ query: {shop_id: shop.id, createdAt: {$gt: _vm.$day().subtract(2, 'months').toISOString()}} }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var total = ref.total;
return [(shop.usesOrdering && total)?_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-check")]):_vm._e()]}}],null,true)})]}},{key:"item.usesInvoicing",fn:function(ref){
var shop = ref.item;
return [_c('FeathersVuexCount',{attrs:{"service":"invoices","params":{ query: {shop_id: shop.id, createdAt: {$gt: _vm.$day().subtract(2, 'months').toISOString()}} }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var total = ref.total;
return [(shop.usesInvoicing && total)?_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-check")]):_vm._e()]}}],null,true)})]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }